const Time = ({ timeConfig }) => {
    return <div style={{
        color: "white"
    }}>
        {timeConfig.hours > 0 ? formatTime(timeConfig.hours) : ""}
                    {formatTime(timeConfig.minutes)}:
                    {formatTime(timeConfig.seconds)}
    </div>
}

const formatTime = (time) => {
    return String(time).padStart(2, "0");
};

export default Time;