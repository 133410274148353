import React, { useState, useEffect } from "react";
import CustomVideoGrid from "./CustomVideoGrid";
import {
  LocalVideo,
  useLocalVideo,
  useRosterState,
  useRemoteVideoTileState,
  useAttendeeStatus,
  MeetingStatus,
  useMeetingStatus,
  useToggleLocalMute,
} from "amazon-chime-sdk-component-library-react";
import SingleAttendee from "./SingleAttendee";
import altraLogo from "../assets/svgs/altraLogo.svg";
import useWindowDimensions from "../helpers/useWindowDimensions";

function arediff(l1, l2) {
  let n1 = l1.length,
    n2 = l2.length;
  if (n1 !== n2) return true;
  for (let i = 0; i < n1; i++) {
    if (!l2.includes(l1[i])) return true;
  }
  return false;
}

function VideoTiles({
  attendeeId,
  toggleVideoOnOnStart,
  toggleAudioOnOnStart,
  activeSpeakers,
  isSharing,
}) {
  const [alist, setAlist] = useState([]);
  const [camList, setCamList] = useState([]);
  const { roster } = useRosterState();
  const attendees = Object.values(roster);

  // Keep this unused variables for future references. Because these are chime SDK values.
  const { tiles, tileIdToAttendeeId, attendeeIdToTileId, size } =
    useRemoteVideoTileState();

  // Keep this unused variables for future references. Because these are chime SDK values.
  let status = useAttendeeStatus(attendeeId);

  // Keep this unused variables for future references. Because these are chime SDK values.
  // For getting window dimensions(alternative to media query)
  const { windowHeight, windowWidth } = useWindowDimensions();

  // Dynamic tile handling states
  const [chosenExpandedTitleId, setChosenExpandedTileId] = useState("");
  const [dimensions, setDimensions] = useState([]);
  const [normalTileColumnSpan, setNormalTileColumnSpan] = useState([]);

  let attendeesWithVideo = [];

  for (let key in tileIdToAttendeeId) {
    attendeesWithVideo.push(tileIdToAttendeeId[key]);
  }
  const allAttendees = attendees.reduce((result, el) => {
    if (el.name === "Altra") {
      result.unshift(el.chimeAttendeeId);
    } else {
      result.push(el.chimeAttendeeId);
    }
    return result;
  }, []);

  if (arediff(allAttendees, alist)) {
    setAlist([...allAttendees]);
  }

  if (arediff(attendeesWithVideo, camList)) {
    setCamList([...attendeesWithVideo]);
  }

  const meetingStatus = useMeetingStatus();
  const { muted, toggleMute } = useToggleLocalMute();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();

  let localAttendeeName = "";
  let localUserProfileImgUrl;

  attendees.map((attendee) => {
    const { chimeAttendeeId, profileImage, name } = attendee;
    if (chimeAttendeeId == attendeeId) {
      localAttendeeName = name;
      localUserProfileImgUrl = profileImage;
    }
  });

  useEffect(() => {
    async function toggle() {
      if (meetingStatus === MeetingStatus.Succeeded && toggleVideoOnOnStart) {
        await toggleVideo();
      }
      if (meetingStatus === MeetingStatus.Succeeded && !toggleAudioOnOnStart) {
        await toggleMute();
      }
    }
    toggle();
  }, [meetingStatus]);

  const isSpeaking = (user) => {
    return activeSpeakers.includes(user);
  };

  return !isSharing ? (
    <div
      id="remote-video-container"
      style={{
        overflow: "auto",
      }}
    >
      {allAttendees.map((el, index) => {
        if (!camList.includes(el)) {
          let _profileImgUrl = attendees.find(
            (attendee) => attendee.chimeAttendeeId == allAttendees[index]
          )?.["profileImage"];
          let _attendeeName = attendees.find(
            (attendee) => attendee.chimeAttendeeId == allAttendees[index]
          )?.["name"];
          let _firstName = "",
            _lastName = "";
          if (_attendeeName) {
            _firstName = _attendeeName.split(" ")[0]?.[0]?.toUpperCase() || "";
            _lastName = _attendeeName.split(" ")[1]?.[0]?.toUpperCase() || "";
          }

          return isVideoEnabled &&
            _attendeeName != undefined &&
            localAttendeeName != undefined &&
            _attendeeName == localAttendeeName ? (
            <div className={`${"attendee-video-container"} `}>
              <LocalVideo />
            </div>
          ) : (
            <div className={`${"attendee-video-container"} `}>
              {_profileImgUrl && _profileImgUrl != "" ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "180px",
                    width: "180px",
                    maxHeight: "100%",
                    maxWidth: "100%",
                    borderRadius: "50%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <img
                    className={`${isSpeaking(el) && "isSpeaking"}`}
                    style={{
                      backgroundColor: "#47484D",
                      height: "180px",
                      width: "180px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                    src={_profileImgUrl}
                    alt={"Carlie Anglemire"}
                  />
                </div>
              ) : (
                <div
                  className={`userCircle ${isSpeaking(el) && "isSpeaking"}`}
                  style={{
                    width: "180px",
                    height: "180px",
                    background: "black",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    fontWeight: "500",
                    fontSize: "40px",
                  }}
                >
                  {_attendeeName == "Altra" ? (
                    <img
                      className="altraImage"
                      width={"70px"}
                      height={"70px"}
                      src={altraLogo}
                      color="white"
                      alt="brand logo"
                    />
                  ) : (
                    `${_firstName}${_lastName}`
                  )}
                </div>
              )}
              <SingleAttendee id={el} />
            </div>
          );
        }
      })}

      <CustomVideoGrid
        normalTileStyle={
          normalTileColumnSpan.length !== 0
            ? {
                gridColumn: normalTileColumnSpan,
              }
            : {}
        }
        expandedTileStyle={
          dimensions.length !== 0
            ? {
                gridColumn: `${dimensions[0]} / ${dimensions[1]}`,
                gridRow: `${dimensions[2]} / ${dimensions[3]}`,
              }
            : {}
        }
        expandedTileId={chosenExpandedTitleId}
      />
    </div>
  ) : (
    <div
      style={{ width: "200px", display: "block", overflow: "auto" }}
      id="remote-video-container"
      className="sidebar"
    >
      {allAttendees.map((el, index) => {
        if (!camList.includes(el)) {
          let _profileImgUrl = attendees.find(
            (attendee) => attendee.chimeAttendeeId == allAttendees[index]
          )?.["profileImage"];
          let _attendeeName = attendees.find(
            (attendee) => attendee.chimeAttendeeId == allAttendees[index]
          )?.["name"];
          let _firstName = "",
            _lastName = "";
          if (_attendeeName) {
            _firstName = _attendeeName.split(" ")[0]?.[0]?.toUpperCase() || "";
            _lastName = _attendeeName.split(" ")[1]?.[0]?.toUpperCase() || "";
          }
          return (
            <div
              style={{
                backgroundColor: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "200px",
                height: "200px",
                marginTop: "8px",
                marginLeft: "8px",
              }}
              className={`${"attendee-video-container "} `}
            >
              {_profileImgUrl ? (
                <div
                  className={`${isSpeaking(el) && "isSpeaking"}`}
                  style={{
                    background: _profileImgUrl
                      ? `url(${_profileImgUrl})`
                      : "#000000",
                    height: "100%",
                    width: "100%",
                    maxHeight: "100%",
                    maxWidth: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
              ) : (
                <div
                  className={`${isSpeaking(el) && "isSpeaking"}`}
                  style={{
                    width: "100px",
                    height: "100px",
                    background: "black",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    fontWeight: "500",
                    fontSize: "20px",
                  }}
                >
                  {_attendeeName == "Altra" ? (
                    <img
                      width={"50px"}
                      height={"50px"}
                      src={altraLogo}
                      color="white"
                      alt="brand logo"
                    />
                  ) : (
                    `${_firstName}${_lastName}`
                  )}
                </div>
              )}
              <SingleAttendee id={el} smallSize={true} />
            </div>
          );
        }
      })}

      <CustomVideoGrid smallSize={true} />
    </div>
  );
}

export default VideoTiles;
