import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  useMeetingManager,
  useLocalVideo,
  useContentShareControls,
  useToggleLocalMute,
  useContentShareState,
  useRosterState,
  useVoiceFocus,
} from "amazon-chime-sdk-component-library-react";
import {
  ConsoleLogger,
  DefaultDeviceController,
  LogLevel,
  VoiceFocusDeviceTransformer,
} from "amazon-chime-sdk-js";
import cdkExports from "../cdk-outputs.json";
import ScreenShare from "./ScreenShare.js";
import VideoTiles from "./VideoTiles";
import "./styles.scss";
import videoff from "../assets/svgs/videoOff.svg";
import videoon from "../assets/svgs/videoOn.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./loader";
import Login from "./login";
import WaitingRoom from "./waiting_room";
import { useStopwatch } from "react-timer-hook";
import Header from "./MeetingComponents/Header/Header.js";
import micOff from "../assets/svgs/micOff.svg";
import micOn from "../assets/svgs/micOn.svg";

const axios = require("axios");
const API_URL = cdkExports.MediaCaptureDemo.apiURL;
const region = "us-east-1";

const JoinMeeting = () => {
  const history = useHistory();
  const meetingManager = useMeetingManager();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const [isRecording, setIsRecording] = useState(false);
  const [meetingDetails, setMeetingDetails] = useState("");
  const [mediaPipeLine, setMediaPipeLine] = useState("");
  const [isMeeting, setisMeeting] = useState(0);
  const { toggleContentShare } = useContentShareControls();
  const { muted, toggleMute } = useToggleLocalMute();
  const [showList, setShowList] = useState(false);
  const [attendeeId, setAttendeeId] = useState("");
  const { sharingAttendeeId } = useContentShareState();
  const [showRecordingMessage, setShowRecordingMessage] = useState(true);
  const [meetTitle, setMeetTitle] = useState("");
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const [info, setInfo] = useState();
  const [isLeaveButtonPressed, setIsLeaveButtonPressed] = useState(false);
  const [isJoinButtonPressed, setIsJoinButtonPressed] = useState(false);
  const [loaderTitle, setLoaderTitle] = useState(
    "Please wait! We are getting you in..."
  );
  const [showLoader, setShowLoader] = useState(false);
  const [functionInstanceIdAvailable, setFunctionInstanceIdAvailable] =
    useState(false);
  const [instanceLevelFunctionId, setInstanceLevelFunctionId] = useState("000");
  const [confirmScreen, setConfirmScreen] = useState(false);
  const [confirmScreenAudio, setConfirmScreenAudio] = useState(true);
  const [confirmScreenVideo, setConfirmScreenVideo] = useState(false);
  const [joinInfo, setJoinInfo] = useState();
  const [validationResponse, setValidationResponse] = useState();
  const [meetingCode, setMeetingCode] = useState("");
  const [email, setEmail] = useState("");
  const [isMeetingEnded, setIsMeetingEnded] = useState(false);
  const [autoLogin, setAutoLogin] = useState(false);
  const [authTokenLocal, setAuthTokenLocal] = useState();
  const [reconnect, setReconnect] = useState(false);

  const [waitingRoomCamera, setWaitingRoomCamera] = useState(true);

  const [waitingRoomAudio, setWaitingRoomAudio] = useState(true);
  const [timeConfig, setTimeConfig] = useState({});
  const { minutes, hours, seconds, start } = useStopwatch({});
  const [screen, setScreen] = useState("");
  const [meetingElapsedDuration, setMeetingElapsedDuration] = useState("");
  const [currentlySharing, setCurrentlySharing] = useState(false);

  //active speaker detection states
  const [activeSpeakers, setActiveSpeakers] = useState([]);
  const [activeSpeakersTimestampMap, setActiveSpeakersTimeStampMap] = useState(
    []
  );

  // delaying action button container so as to fix initial states(audio & video defaults) before render
  const [showActionButtonContainer, setShowActionButtonContainer] =
    useState(false);

  const [previewImage, setPreviewImage] = useState("");
  const [previewName, setPreviewName] = useState("");
  // const [instanceLevelFunctionId, setInstanceLevelFunctionId] = useState(null);
  const { isVoiceFocusSupported, addVoiceFocus } = useVoiceFocus();

  // meeting states
  const prodEnv = "prod";
  const gammaEnv = "gamma";
  const betaEnv = "beta";

  //passcode 431704
  const queryParams = new URLSearchParams(window.location.search);
  var meeting_id;
  var meeting_title;
  var env;
  var added_on;
  // ID that differentiate the every instance of the recurring meeting.
  var instance_level_function_id;
  var meeting_url;

  if (
    queryParams.get("meeting_id") &&
    queryParams.get("meeting_title") &&
    queryParams.get("env")
  ) {
    meeting_id = queryParams.get("meeting_id");
    meeting_title = queryParams.get("meeting_title").split("_").join(" ");
    added_on = parseInt(queryParams.get("added_on"));
    env = queryParams.get("env");
    meeting_url = window.location.href;
    if (queryParams.get("instance_level_function_id")) {
      instance_level_function_id = queryParams.get(
        "instance_level_function_id"
      );
    } else {
      instance_level_function_id = "testing_purpose";
    }
  }

  useEffect(() => {
    // Call the async function
    fetchData();
  }, []);

  const fetchData = async () => {
    if (
      meeting_id &&
      added_on &&
      meeting_title &&
      instance_level_function_id !== null
    ) {
      try {
        // API request to get the instance_level_function_id
        const instanceId = await getInstanceLevelFunctionId(meeting_id);
        setInstanceLevelFunctionId(instanceId);
        setFunctionInstanceIdAvailable(true);
        const queryParams = new URLSearchParams(window.location.search);
        var code = queryParams.get("code");
        var email = queryParams.get("email");
        // Construct the modified meeting link with instance_level_function_id
        var modifiedLink;
        if (code) {
          modifiedLink = `/?env=${env}&meeting_id=${meeting_id}&added_on=${added_on}&meeting_title=${meeting_title}&instance_level_function_id=${instanceId}&code=${code}&email=${email}`;
        } else {
          modifiedLink = `/?env=${env}&meeting_id=${meeting_id}&added_on=${added_on}&meeting_title=${meeting_title}&instance_level_function_id=${instanceId}`;
        }

        // Redirect the user to the modified link
        history.push(modifiedLink);
        // setFunctionInstanceIdAvailable(true)
      } catch (error) {
        // Handle any errors that occur during the API request
        console.error("Error fetching instance_level_function_id:", error);
      }
    }
  };

  async function getInstanceLevelFunctionId(meeting_id) {
    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    var url = "";
    if (env == prodEnv) {
      url =
        "https://a7kvew0r18.execute-api.us-east-1.amazonaws.com/prod/v1/redirect_meeting_conference_tool_link";
    } else if (env == gammaEnv) {
      url =
        "https://5s6n83h8t1.execute-api.us-east-1.amazonaws.com/gamma/v1/redirect_meeting_conference_tool_link";
    } else {
      url =
        "https://mpqjlgos56.execute-api.us-east-1.amazonaws.com/dev/v1/redirect_meeting_conference_tool_link";
    }
    const header = {
      "Content-Type": "applications/json",
    };
    const payload = {
      "py/object":
        "ad_conference_tool_api_types.redirect_meeting_link.RedirectMeetingConferenceToolLinkInput",
      meeting_id: meeting_id,
      current_timestamp: currentTimestampInSeconds,
    };
    const validationRequest = {
      url: url,
      method: "post",
      headers: header,
      data: payload,
    };

    const apiResp = await axios(validationRequest);

    if (apiResp != null && typeof apiResp.data == "string") {
      const instanceLevelFunctionId = apiResp.data;
      console.log(
        "Successfully fetched the instance level function ID " +
          instanceLevelFunctionId +
          " for the given meeting ID " +
          meeting_id
      );
      return instanceLevelFunctionId;
    } else {
      console.log(
        "Failed to fetch the instance level function ID for the given meeting ID " +
          meeting_id
      );
    }
  }

  useEffect(() => {
    const callback = (activeSpeakers) => {
      const date = Date.now();

      const _activeSpeakersToTimeStamp = {
        [date]: activeSpeakers,
      };
      let _tempArr = activeSpeakersTimestampMap;
      _tempArr.push(_activeSpeakersToTimeStamp);

      setActiveSpeakersTimeStampMap(_tempArr);
      setActiveSpeakers(activeSpeakers);
    };

    meetingManager.subscribeToActiveSpeaker(callback);
    return () => {
      meetingManager.unsubscribeFromActiveSpeaker(callback);
    };
  }, []);

  useEffect(() => {
    if (currentlySharing) setCurrentlySharing(false);
    if (sharingAttendeeId) {
      let _sharingAttendeeId = sharingAttendeeId.split("#")[0];
      if (_sharingAttendeeId == attendeeId) {
        setCurrentlySharing(true);
      }
    }
  }, [sharingAttendeeId]);

  useEffect(() => {
    setTimeConfig({
      hours,
      minutes,
      seconds,
    });
  }, [hours, minutes, seconds]);

  https: useEffect(() => {
    setIsMeetingEnded(false);
    if (queryParams.get("code")) {
      setAutoLogin(true);
    }

    if (queryParams.get("code") && queryParams.get("email")) {
      setMeetingCode(queryParams.get("code"));
      setEmail(queryParams.get("email"));
    }
    meetingManager.getAttendee = async (chimeAttendeeId, chimeMeetingId) => {
      var url = "";
      if (env == prodEnv) {
        url =
          "https://a7kvew0r18.execute-api.us-east-1.amazonaws.com/prod/v1/get_attendee_details";
      } else if (env == gammaEnv) {
        url =
          "https://5s6n83h8t1.execute-api.us-east-1.amazonaws.com/gamma/v1/get_attendee_details";
      } else {
        url =
          "https://mpqjlgos56.execute-api.us-east-1.amazonaws.com/dev/v1/get_attendee_details";
      }

      const headers = {
        "Content-Type": "applications/json",
      };

      const payload = {
        "py/object":
          "ad_conference_tool_api_types.get_attendee.GetAttendeeDetailsInput",
        attendee_id: chimeAttendeeId,
        chime_meeting_id: meetingManager.meetingId,
      };

      const validationRequest = {
        url: url,
        method: "post",
        headers: headers,
        data: payload,
      };

      const attendeeResponse = await axios(validationRequest);

      console.log(
        "Get attendee API call response = " + JSON.stringify(attendeeResponse)
      );

      if (attendeeResponse["status"] == 200) {
        const attendeeData = attendeeResponse["data"]["attendee"];
        const attendeeName = attendeeData["name"];

        var _profileImage = null;
        var _role = null;

        if (attendeeData["member_details"] != null) {
          if (attendeeData["member_details"]["profile_picture_url"] != null) {
            _profileImage =
              attendeeData["member_details"]["profile_picture_url"];
          }
          if (attendeeData["member_details"]["role"] != null) {
            _role = attendeeData["member_details"]["role"];
          }
        }

        return {
          name: attendeeName,
          profileImage: _profileImage,
          role: _role,
        };
      }
    };

    const showRecordingMessageTimer = setTimeout(
      () => setShowRecordingMessage(false),
      10000
    );

    return () => {
      setConfirmScreenVideo(false);
      setConfirmScreenAudio(true);
      meetingManager.leave();
      setisMeeting(0);
      clearTimeout(showRecordingMessageTimer);
    };
  }, []);

  useEffect(async () => {
    if (isMeeting == 1) {
      await handleRecording();
      //starting clock
      start();
      setTimeout(() => {
        setShowActionButtonContainer(true);
      }, 2000);
    }
    setupNoiseSuppression();
  }, [isMeeting]);

  const toggleList = (event) => {
    event.preventDefault();
    if (showList) {
      setShowList(false);
    } else {
      setShowList(true);
    }
  };
  const notify = (message) => {
    toast.error(message);
  };

  const previewMeetingCamera = () => {
    if (waitingRoomCamera) {
      setWaitingRoomCamera(false);
    } else {
      setWaitingRoomCamera(true);
    }
  };

  const previewMeetingAudio = () => {
    if (waitingRoomAudio) {
      setWaitingRoomAudio(false);
    } else {
      setWaitingRoomAudio(true);
    }
  };

  async function applyHighPassFilter(audioVideo) {
    const audioContext = audioVideo.audioContext;

    const audioInputDevice =
      await audioVideo.deviceController.listAudioInputDevices();
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: audioInputDevice[0].deviceId },
    });

    // Create a MediaStreamSource from the device
    const sourceNode = audioContext.createMediaStreamSource(mediaStream);

    // Create a High-Pass Filter
    const highPassFilter = audioContext.createBiquadFilter();
    highPassFilter.type = "highpass";
    highPassFilter.frequency.value = 1000; // Removes noise below 200 Hz

    // Connect nodes
    sourceNode.connect(highPassFilter);

    // Create a destination node to produce a filtered MediaStream
    const destinationNode = audioContext.createMediaStreamDestination();
    highPassFilter.connect(destinationNode);

    // Get the filtered MediaStream
    const filteredMediaStream = destinationNode.stream;

    const audioTracks = filteredMediaStream.getAudioTracks();
    if (audioTracks.length > 0) {
      console.log("Filtered stream audio track:", audioTracks[0]);
    } else {
      console.error("Filtered stream has no audio tracks.");
    }

    // Set the filtered MediaStream as the audio input device for Chime
    await audioVideo.deviceController.chooseAudioInputDevice(
      filteredMediaStream
    );
    console.log("Filtered MediaStream:", filteredMediaStream);
    console.log(audioVideo.deviceController);
    console.log(
      "Selected audio input device:",
      audioVideo.deviceController.activeAudioInputDevice
    );
    console.log("High-pass filter applied and audio input set to Chime.");
  }

  const setupNoiseSuppression = async () => {
    // Setup logging
    const logger = new ConsoleLogger("SDKLogs", LogLevel.INFO);

    try {
      // Check if Web Audio is supported and initialize AudioContext if needed
      if (
        typeof AudioContext !== "undefined" ||
        typeof webkitAudioContext !== "undefined"
      ) {
        console.log("Web Audio API is supported!");

        // Optionally, check if the AudioContext is being used in your device controller
        const audioVideo = meetingManager.meetingSession?.audioVideo;
        if (audioVideo) {
          console.log(
            "Web Audio enabled:",
            audioVideo.deviceController.useWebAudio
          );
        }
      } else {
        console.error("Web Audio API is not supported in this browser.");
      }

      // Initialize the device controller with Web Audio enabled
      const audioVideo = meetingManager.meetingSession?.audioVideo;
      if (!audioVideo) {
        throw new Error("AudioVideo instance not initialized");
      }

      // Create and initialize the device controller with the logger and Web Audio enabled
      const deviceController = new DefaultDeviceController(logger, {
        enableWebAudio: true,
      });

      // Get the user's audio media
      await navigator.mediaDevices.getUserMedia({ audio: true });

      // Check supported constraints
      const supportedConstraints =
        navigator.mediaDevices.getSupportedConstraints();
      console.log(
        "Supported constraints in this browser:",
        supportedConstraints
      );
      audioVideo.deviceController = deviceController;
      console.log(
        "Web Audio enabled:",
        audioVideo.deviceController.useWebAudio
      );

      // applyHighPassFilter(audioVideo);

      // Assign the device controller to the audioVideo instance

      // Enable VoiceFocus and get the transform device with noise suppression enabled
      // const deviceToUse = await enableVoiceFocus(audioVideo, logger);
      // const audioInputDevices =
      //   await meetingManager.meetingSession.audioVideo.deviceController.listAudioInputDevices();
      // const deviceId = audioInputDevices[0].deviceId;
      // await meetingManager.meetingSession.audioVideo.start({
      //   audioInput: {
      //     deviceId: deviceId,
      //   },
      // });

      const deviceToUse = await addVoiceFocus(audioVideo);
      // const constraints = {
      //   echoCancellation: true,
      //   googEchoCancellation: true,
      //   googAutoGainControl: true,
      //   noiseSuppression: true, // Enable noise suppression
      //   // sampleRate: { ideal: 48000 },
      //   // sampleSize: { ideal: 16 },
      //   // channelCount: { ideal: 1 },
      //   // deviceId: { exact: deviceToUse },
      // };

      // // Apply the constraints to the chosen device (the device has constraints applied before choosing)
      // const deviceWithConstraints = {
      //   ...deviceToUse,
      //   constraints,
      // };

      // Apply the adjusted constraints
      // await audioVideo.chooseAudioInputDevice(deviceWithConstraints);
      // console.log("Applied device:", deviceWithConstraints);
    } catch (error) {
      console.log("Error:", error);
      logger.error("Error setting up noise suppression:", error);
    }
  };

  const enableVoiceFocus = async (audioVideo, logger) => {
    const spec = { assetGroup: "stable-v1", logger };
    const transformer = await VoiceFocusDeviceTransformer.create(spec);
    const chosenAudioInputDevice = "default";

    return transformer
      ? await transformer.createTransformDevice(chosenAudioInputDevice)
      : chosenAudioInputDevice;
  };

  // const setupNoiseSuppression = async () => {
  //   // Setup logging
  //   const logger = new ConsoleLogger("SDKLogs", LogLevel.INFO);

  //   try {
  //     // Check if Web Audio is supported and initialize AudioContext if needed
  //     if (
  //       typeof AudioContext !== "undefined" ||
  //       typeof webkitAudioContext !== "undefined"
  //     ) {
  //       console.log("Web Audio API is supported!");

  //       // Optionally, check if the AudioContext is being used in your device controller
  //       const audioVideo = meetingManager.meetingSession?.audioVideo;
  //       if (audioVideo) {
  //         console.log(
  //           "Web Audio enabled:",
  //           audioVideo.deviceController.useWebAudio
  //         );
  //       }
  //     } else {
  //       console.error("Web Audio API is not supported in this browser.");
  //     }

  //     // Initialize the device controller with Web Audio enabled
  //     const audioVideo = meetingManager.meetingSession?.audioVideo;
  //     if (!audioVideo) {
  //       throw new Error("AudioVideo instance not initialized");
  //     }

  //     // Create and initialize the device controller with the logger and Web Audio enabled
  //     const deviceController = new DefaultDeviceController(logger, {
  //       enableWebAudio: true,
  //     });

  //     // Assign the device controller to the audioVideo instance
  //     audioVideo.deviceController = deviceController;
  //     console.log(
  //       "Web Audio enabled:",
  //       audioVideo.deviceController.useWebAudio
  //     );
  //     // Get the user's audio media
  //     await navigator.mediaDevices.getUserMedia({ audio: true });

  //     // Check supported constraints
  //     const supportedConstraints =
  //       navigator.mediaDevices.getSupportedConstraints();
  //     console.log(
  //       "Supported constraints in this browser:",
  //       supportedConstraints
  //     );

  //     // Enable VoiceFocus and get the transform device
  //     const deviceToUse = await enableVoiceFocus(audioVideo, logger);
  //     console.log(deviceToUse);

  //     // Apply the VoiceFocus-enabled device
  //     const res = await audioVideo.chooseAudioInputDevice(deviceToUse);
  //     console.log(res);
  //   } catch (error) {
  //     console.log("Error:", error);
  //     logger.error("Error setting up noise suppression:", error);
  //   }
  // };

  const startMeeting = async () => {
    if (isVideoEnabled) toggleVideo();

    try {
      await setupNoiseSuppression();

      // Join and start the meeting via meetingManager
      await meetingManager.join(joinInfo);
      await meetingManager.start();

      // logger.info("VoiceFocus enabled with device:", deviceToUse);

      // Update state
      setAttendeeId(joinInfo.attendeeInfo.AttendeeId);
      setInfo(validationResponse.data.data);
      setisMeeting(1);
      setConfirmScreen(false);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
    }
  };

  const handleJoinMeeting = async (
    email,
    meetingCode,
    _isAltraUser = false
  ) => {
    if (isJoinButtonPressed) {
      return;
    }
    setShowLoader(true);
    setLoaderTitle("Please wait! We are getting you in...");
    // setShowLoader(true)
    setIsLeaveButtonPressed(false);
    try {
      var _url = "";
      if (env == prodEnv) {
        _url =
          "https://a7kvew0r18.execute-api.us-east-1.amazonaws.com/prod/v1/verify_meeting_passcode";
      } else if (env == gammaEnv) {
        _url =
          "https://5s6n83h8t1.execute-api.us-east-1.amazonaws.com/gamma/v1/verify_meeting_passcode";
      } else {
        _url =
          "https://mpqjlgos56.execute-api.us-east-1.amazonaws.com/dev/v1/verify_meeting_passcode";
      }
      const headers = {
        "Content-Type": "applications/json",
      };
      const payload = {
        "py/object":
          "ad_conference_tool_api_types.verify_meeting_passcode.VerifyMeetingPasscodeInput",
        meeting_id: meeting_id,
        meeting_code: meetingCode,
        is_altra_user: _isAltraUser,
      };
      console.log("Verify passcode API call payload: " + payload);
      const validationRequest = {
        url: _url,
        method: "post",
        headers: headers,
        data: payload,
      };
      const validationResponse = await axios(validationRequest);
      console.log("Verify passcode API call response: " + validationResponse);
      console.log(JSON.stringify(validationResponse));

      console.log("responseData.status = ", validationResponse["status"]);

      if (validationResponse["status"] != 200) {
        notify("Please check the passcode!");
        setShowLoader(false);
        return;
      }

      const responseData = validationResponse["data"];

      const meeting_record = responseData["meeting_record"];

      var attendeeInfo = [];

      if (
        meeting_record != null &&
        meeting_record["attendee_info"] != null &&
        Array.isArray(meeting_record["attendee_info"])
      ) {
        attendeeInfo = meeting_record["attendee_info"];
      }

      var _currentAttendee = null;
      for (let i = 0; i < attendeeInfo.length; i++) {
        var attendee = attendeeInfo[i];
        if (attendee["email"] == email) {
          _currentAttendee = attendee;
        }
      }

      // let _currentAttendee = validationResponse?.["data"]?.["data"]?.[
      //   "attendee_info"
      // ]?.find(
      //   (attendee) => attendee.email === email && attendee.member_details
      // );

      if (_currentAttendee != null) {
        let _profilePic =
          _currentAttendee["member_details"]?.["userData"]?.[
            "profile_picture_url"
          ] || "";
        setPreviewImage(_profilePic);
        if (_profilePic == "") {
          setPreviewName(
            _currentAttendee["member_details"]?.["userData"]?.["name"]
          );
        }
      } else {
        let _previewName = `${email.substring(0, email.lastIndexOf("@"))}`;
        setPreviewName(_previewName);
      }

      let _workspaceId = meeting_record["workspace_id"];
      setValidationResponse(validationResponse);

      setIsJoinButtonPressed(true);
      var _meeting_url = "";
      if (env == prodEnv) {
        _meeting_url =
          "https://ob6i2hg8r5.execute-api.us-east-1.amazonaws.com/prod/v2/create";
      } else if (env == gammaEnv) {
        _meeting_url =
          "https://sdgtqtrrm9.execute-api.us-east-1.amazonaws.com/gamma/v1/create";
      } else {
        _meeting_url =
          "https://f900jodxxb.execute-api.us-east-1.amazonaws.com/dev/v2/create";
      }
      var instance_level_function_id_local = queryParams.get(
        "instance_level_function_id"
      );
      console.log("meeting_id====", meeting_id);
      console.log("added_on====", added_on);
      console.log("region====", region);
      console.log("email====", email);
      console.log("_workspaceId====", _workspaceId);
      console.log("instanceLevelFunctionId====", instanceLevelFunctionId);
      console.log("meeting_url====", meeting_url);

      const joinRequest = {
        url: _meeting_url,
        method: "post",
        headers: {
          "Content-Type": "applications/json",
        },
        data: {
          meeting_id: meeting_id,
          added_on: added_on,
          region: region,
          email: email,
          workspace_id: _workspaceId,
          instance_level_function_id: instanceLevelFunctionId,
          meeting_url: meeting_url,
        },
      };
      console.log("joinRequest====", joinRequest);
      const meetingInfo = await axios(joinRequest);
      const joinInfo = {
        meetingInfo: meetingInfo.data.Meeting,
        attendeeInfo: meetingInfo.data.Attendee,
        newAddedOn: meetingInfo.data.NewAddedOn,
      };
      setMeetingDetails(joinInfo);

      setConfirmScreen(true);
      setJoinInfo(joinInfo);
    } catch (err) {
      setShowLoader(false);
      notify(err);
      setisMeeting(2);
    }
    setShowLoader(false);
  };

  const handleRecording = async () => {
    var _url = "";
    if (env == prodEnv) {
      _url =
        "https://ob6i2hg8r5.execute-api.us-east-1.amazonaws.com/prod/v2/record";
    } else if (env == gammaEnv) {
      _url =
        "https://sdgtqtrrm9.execute-api.us-east-1.amazonaws.com/gamma/v1/record";
    } else {
      _url =
        "https://f900jodxxb.execute-api.us-east-1.amazonaws.com/dev/v2/record";
    }
    const recordRequest = {
      url: _url,
      method: "post",
      headers: {
        "Content-Type": "applications/json",
      },
      data: {
        meetingId: "",
        setRecording: isRecording,
        mediaPipeLine: "",
        attendeeId,
      },
    };

    if (isRecording) {
      recordRequest.data.mediaPipeLine = mediaPipeLine;
      try {
        await axios(recordRequest);
      } catch (err) {}
    } else {
      recordRequest.data.meetingId = meetingDetails.meetingInfo.MeetingId;
      try {
        const recordingInfo = await axios(recordRequest);
        setMediaPipeLine(
          recordingInfo.data.MediaCapturePipeline.MediaPipelineId
        );
      } catch (err) {}
    }
    setIsRecording(!isRecording);
  };

  const updateMeetingAndAttendeeEndTime = async function (
    updateOnlyAttendeeTime
  ) {
    try {
      var _meeting_url = "";
      if (env == prodEnv) {
        _meeting_url =
          "https://ob6i2hg8r5.execute-api.us-east-1.amazonaws.com/prod/v2/create";
      } else if (env == gammaEnv) {
        _meeting_url =
          "https://sdgtqtrrm9.execute-api.us-east-1.amazonaws.com/gamma/v1/create";
      } else {
        _meeting_url =
          "https://f900jodxxb.execute-api.us-east-1.amazonaws.com/dev/v2/create";
      }
      var payload;
      if (updateOnlyAttendeeTime) {
        payload = {
          action: "UPDATE_ATTENDEE_TIME",
          meeting_id: meeting_id,
          added_on: meetingDetails.newAddedOn,
          attendee_id: meetingDetails.attendeeInfo.AttendeeId,
        };
      } else {
        payload = {
          action: "UPDATE_MEETING_AND_ATTENDEE_TIME",
          meeting_id: meeting_id,
          added_on: meetingDetails.newAddedOn,
          attendee_id: meetingDetails.attendeeInfo.AttendeeId,
        };
      }
      const updateRequest = {
        url: _meeting_url,
        method: "post",
        headers: {
          "Content-Type": "applications/json",
        },
        data: payload,
      };

      var updateResponse = await axios(updateRequest);
      return true;
    } catch (err) {
      return false;
    }
  };

  const handleLeave = async function (event) {
    if (isLeaveButtonPressed) {
      return;
    }
    setLoaderTitle("Please wait! We are updating the status...");
    setShowLoader(true);
    setIsLeaveButtonPressed(true);
    setIsJoinButtonPressed(false);
    try {
      event.preventDefault();
      const updateMeetingAndAttendeeEndTimeResponse =
        await updateMeetingAndAttendeeEndTime(true);
      await meetingManager.leave();
      setScreen("meetingEnded");
      setMeetingElapsedDuration(`${
        timeConfig.hours > 0 ? formatTime(timeConfig.hours) : ""
      }
      ${formatTime(timeConfig.minutes)} : 
      ${formatTime(timeConfig.seconds)}`);
      if (autoLogin) {
        /// this console log should not be removed , since it is used by mobile app to detect meeting getting ended
        console.log("closeWebview");
        setShowLoader(true);
        setLoaderTitle("Getting you back to workspace...");
        window.close();
      } else {
        setIsMeetingEnded(true);
        setisMeeting(0);
        setShowLoader(false);
      }
    } catch (error) {
      if (autoLogin) {
        /// this console log should not be removed , since it is used by mobile app to detect meeting getting ended
        console.log("closeWebview");
        window.close();
        setShowLoader(true);
        setLoaderTitle("Getting you back to workspace..");
      }
      await meetingManager.leave();
      setIsMeetingEnded(true);
      setisMeeting(0);
      setShowLoader(false);
    }
  };

  const handleContentShare = () => {
    toggleContentShare();
  };

  const toggleLocalAudio = () => {
    setConfirmScreenAudio(!confirmScreenAudio);
  };

  const formatTime = (time) => {
    return String(time).padStart(2, "0");
  };

  const toggleLocalVideo = () => {
    // if(meetingManager.audioVideo?.realtimeIsLocalAudioMuted()) return;
    // meetingManager.audioVideo?.realtimeMuteLocalVideo();
    setConfirmScreenVideo(!confirmScreenVideo);
  };

  const handleDismissButtonClick = () => {
    // setScreen("");
  };
  return (
    <div className="confirmScreenWrapper">
      {confirmScreen ? (
        <div className="confirmJoinContainer">
          <div>
            {/* {confirmScreenVideo ? (
              <PreviewVideo className="previewVideoContainer" />
            ) : (
              <div className="previewVideoContainer videoPlaceholder">
                Joining as {userName}
              </div>
            )} */}
            <div className="previewVideoContainer videoPlaceholder">
              {waitingRoomCamera ? (
                <WaitingRoom waitingRoomAudio={waitingRoomAudio} />
              ) : (
                <div className="previewVideoContainer videoPlaceholder">
                  {/* Joining as {attendeeName} */}
                  {previewImage != "" ? (
                    <img
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                      }}
                      src={previewImage}
                      alt="profile pic"
                    />
                  ) : (
                    <span>Joining as {previewName}</span>
                  )}
                </div>
              )}
            </div>
            <div className="previewVideoActionContainer">
              <div className="previewVideoActionButtons">
                {waitingRoomAudio ? (
                  <div
                    id="voice"
                    className="previewVideoActionButton"
                    onClick={previewMeetingAudio}
                  >
                    <img className="mute1" src={micOn} alt="mute1" />
                  </div>
                ) : (
                  <div
                    id="voice"
                    className="previewVideoActionButton"
                    onClick={previewMeetingAudio}
                  >
                    <img className="mute1" src={micOff} alt="mute1" />
                  </div>
                )}
                {waitingRoomCamera ? (
                  <div
                    id="video"
                    className="previewVideoActionButton"
                    onClick={previewMeetingCamera}
                  >
                    <img className="videoon" src={videoon} alt="videoon" />
                  </div>
                ) : (
                  <div
                    id="video"
                    className="previewVideoActionButton"
                    onClick={previewMeetingCamera}
                  >
                    <img className="videoff" src={videoff} alt="videoff" />
                  </div>
                )}
              </div>
              <button className="joinButton" onClick={startMeeting}>
                Join
              </button>
            </div>
          </div>
        </div>
      ) : screen == "meetingEnded" ? (
        <div className="fullscreen">
          <div className="meetingEndedInfoContainer">
            <div className="meetingEndedInfoTitle">Meeting Ended</div>
            <div className="meetingEndedInfoDuration">
              Duration {meetingElapsedDuration} &nbsp;
            </div>
          </div>
        </div>
      ) : (
        <>
          {/* <ToastContainer /> */}
          {isMeeting == 0 || isMeeting == 2 ? (
            functionInstanceIdAvailable ? (
              <Login
                isMeetingEnded={isMeetingEnded}
                handleJoinMeeting={(email, meetingCode, isAltraUser) =>
                  handleJoinMeeting(email, meetingCode, isAltraUser)
                }
                meetingTitle={meeting_title}
              />
            ) : (
              <Loader showLoader={true} loaderTitle="Fetching details..." />
            )
          ) : (
            <>
              <Header
                timeConfig={timeConfig}
                title={meeting_title}
                muted={muted}
                toggleMute={toggleMute}
                isVideoEnabled={isVideoEnabled}
                toggleVideo={toggleVideo}
                currentlySharing={currentlySharing}
                handleContentShare={handleContentShare}
                handleLeave={handleLeave}
                toggleParticipantList={toggleList}
              />

              <div className="main-container2">
                {showRecordingMessage ? (
                  <div className="recording-message">
                    This meeting is being recorded
                  </div>
                ) : (
                  <></>
                )}

                <div id="video-container">
                  <ScreenShare attendeeId={sharingAttendeeId} />
                  <VideoTiles
                    activeSpeakers={meetingManager.activeSpeakers}
                    attendeeId={attendeeId}
                    toggleAudioOnOnStart={waitingRoomAudio}
                    toggleVideoOnOnStart={waitingRoomCamera}
                    isSharing={sharingAttendeeId != null}
                  />
                </div>
              </div>
            </>
          )}
          <Loader showLoader={showLoader} loaderTitle={loaderTitle} />
        </>
      )}
    </div>
  );
};

export default JoinMeeting;
