import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import {
  MeetingProvider,
  VoiceFocusProvider,
  lightTheme,
} from "amazon-chime-sdk-component-library-react";
import { Provider } from "react-redux";
import store from "./redux/store";

// ReactDOM.render(<App />, document.getElementById('root'));
render(
  <Provider store={store}>
    <ThemeProvider theme={lightTheme}>
      <VoiceFocusProvider>
        <MeetingProvider>
          <App />
        </MeetingProvider>
      </VoiceFocusProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
