import "../../styles.scss";
import videoff from "../../../assets/svgs/videoOff.svg";
import videoon from "../../../assets/svgs/videoOn.svg";

const VideoButton = ({ isVideoEnabled, toggleVideo }) => {
    if (isVideoEnabled) {
        return <div id="video" className="bluebg" onClick={toggleVideo}>
        <img className="videoon" src={videoon} alt="videoff" />
    </div>
    } else {
        return <div id="video" className="greyBg" onClick={toggleVideo}>
        <img className="videoff" src={videoff} alt="videoff" />
    </div>
    }
}

export default VideoButton;