import "../../styles.scss";
import RecordingIndicator from "./RecordingIndicator.js";
import Time from "./Time.js";
import VideoButton from "./VideoButton.js";
import AudioButton from "./AudioButton.js";
import ShareButton from "./ShareButton.js";
import LeaveButton from "./LeaveButton.js";
import ParticipantsButton from "./ParticipantsButton.js";

const Header = ({
  timeConfig,
  title,
  muted,
  toggleMute,
  isVideoEnabled,
  toggleVideo,
  currentlySharing,
  handleContentShare,
  handleLeave,
  toggleParticipantList,
}) => {
  return (
    <div className="child1">
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <RecordingIndicator />
        <div style={{ width: "10px" }} />
        <Time timeConfig={timeConfig} />
      </div>
      {window.innerWidth > 600 && (
        <div
          style={{
            flex: 1,
            color: "white",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {title}
        </div>
      )}
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        className="action-buttons-container"
      >
        <AudioButton muted={muted} toggleMute={toggleMute} />
        <VideoButton
          isVideoEnabled={isVideoEnabled}
          toggleVideo={toggleVideo}
        />
        <ShareButton
          currentlySharing={currentlySharing}
          handleContentShare={handleContentShare}
        />
        {/* Temporarily commented until the participant list layout is fixed */}
        {/* <ParticipantsButton
                toggleList={toggleParticipantList} /> */}
        <LeaveButton handleLeave={handleLeave} />
      </div>
    </div>
  );
};

export default Header;
