import React from "react";
import {
  useRemoteVideoTileState,
  RemoteVideo,
  useContentShareState,
} from "amazon-chime-sdk-component-library-react";
import SingleAttendee from "./SingleAttendee";

const CustomVideoGrid = ({ smallSize }) => {
  const { sharingAttendeeId } = useContentShareState();

  const { tiles, tileIdToAttendeeId, attendeeIdToTileId, size } =
    useRemoteVideoTileState();
  const videos = tiles.map((tileId, index) => {
    return (
      <div className="attendee-video-container">
        <RemoteVideo
          tileId={tileId}
        />
        <SingleAttendee id={tileIdToAttendeeId[tileId]} smallSize={smallSize} />
      </div>
    );
  });
  return <>{videos}</>;
};

export default CustomVideoGrid;
