import "../../styles.scss";
import record from "../../../images/record.svg";

const RecordingIndicator = () => {
    return <img 
        src={record} 
        alt="record" 
        style={{ 
            width: "20px",
            height: "20px",
        }} 
    />
}

export default RecordingIndicator;