import "../../styles.scss";
import micOff from "../../../assets/svgs/micOff.svg";
import micOn from "../../../assets/svgs/micOn.svg";

const AudioButton = ({ muted, toggleMute }) => {
    if (muted) {
        return <div 
            id="voice" 
            className="greyBg" 
            onClick={toggleMute}
        >
            <img className="mute1" src={micOff} alt="mute" />
        </div>
    } else {
        return <div id="voice" className="bluebg" onClick={toggleMute}>
            <img className="mute1" src={micOn} alt="mute" />
        </div>
    }
}

export default AudioButton;