import "../../styles.scss";
import screenShareIcon from "../../../assets/svgs/screenShare.svg";
import xmarkIcon from "../../../assets/svgs/xmark.svg";

const ShareButton = ({ currentlySharing, handleContentShare }) => {
    if (currentlySharing) {
        return <div
            id="screen-share"
            className="whiteBg"
            onClick={handleContentShare}>
            <img className="screen" src={xmarkIcon} alt="screen" />
        </div>
    } else {
        return <div
            id="screen-share"
            className="greyBg"
            onClick={handleContentShare}>
            <img
                className="screen"
                src={screenShareIcon}
                alt="screen" />
        </div>
    }
}

export default ShareButton;